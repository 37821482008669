import { useState, useEffect } from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./App.css";

function App() {
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [typedText, setTypedText] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [charIndex, setCharIndex] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);

  const toggleBackgroundColor = () => {
    setIsDarkMode((prevMode) => !prevMode);
  };

  useEffect(() => {
    const words = [
      "Une nouvelle manière de rénover votre intérieur",
      "Votre outil pour sublimer vos oeuvres d'arts à travers la France",
      "Le mariage parfait entre l'art et l'innovation",
    ];
    const typingSpeed = 110;
    const deletingSpeed = 110;
    const pauseDuration = 1500;

    if (isDeleting) {
      if (charIndex > 0) {
        setTimeout(() => setCharIndex((prev) => prev - 1), deletingSpeed);
        setTypedText(words[currentWordIndex].slice(0, charIndex));
      } else {
        setIsDeleting(false);
        setCurrentWordIndex((prev) => (prev + 1) % words.length);
      }
    } else {
      if (charIndex < words[currentWordIndex].length) {
        setTimeout(() => setCharIndex((prev) => prev + 1), typingSpeed);
        setTypedText(words[currentWordIndex].slice(0, charIndex + 1));
      } else {
        setTimeout(() => setIsDeleting(true), pauseDuration);
      }
    }
  }, [charIndex, isDeleting, currentWordIndex]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitStatus(null);

    const email = e.target.elements.email.value;

    if (!email) {
      setSubmitStatus({ type: 'error', message: 'Please enter a valid email.' });
      setIsSubmitting(false);
      return;
    }

    try {
      const response = await fetch("https://backend-landingpage-wq8j.onrender.com/send-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json"
        },
        credentials: 'include',
        body: JSON.stringify({ email }),
      });

      const result = await response.json();

      if (response.ok) {
        setSubmitStatus({ type: 'success', message: 'Merci pour votre intérêt lié à notre projet' });
        e.target.reset();
        
        setTimeout(() => {
          const statusElement = document.querySelector('.submit-status');
          if (statusElement) {
            statusElement.classList.add('fade-out');
          }
          
          setTimeout(() => {
            setSubmitStatus(null);
          }, 400);
        }, 3000);
      } else {
        setSubmitStatus({ type: 'error', message: `Failed to send email: ${result.error}` });
      }
    } catch (error) {
      console.error('Error:', error);
      setSubmitStatus({ type: 'error', message: 'An error occurred while sending the email.' });
    } finally {
      setIsSubmitting(false);
    }
};

  return (
    <div className={`App ${isDarkMode ? "dark-mode" : "light-mode"}`}>
      <button 
        className="toggle-button" 
        onClick={toggleBackgroundColor}
        aria-label={isDarkMode ? "Switch to Light Mode" : "Switch to Dark Mode"}
      >
        <i 
          className="bi bi-headset-vr" 
          style={{ 
            fontSize: "28px",
            color: isDarkMode ? "white" : "#0a1f2d"
          }}
        />
      </button>
      <header className="App-header">
        <div className="header-title">
          <h1>Prophecy</h1>
          <div className="xr-box">XR</div>
        </div>
        <h2>DESIGN BEYOND IMAGINATION</h2>
        <p className="highlighted-text">Prophecy XR c'est :</p>
        <p className="highlighted-text typing-text">{typedText}</p>
        <form onSubmit={handleSubmit}>
          <input
            name="email"
            type="email"
            placeholder="Entrez votre email ici pour etre informé "
            className="newsletter-input"
            disabled={isSubmitting}
          />
          <button 
            type="submit" 
            className="newsletter-button"
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Envoi en cours...' : 'Envoyer'}
          </button>
          {submitStatus && (
            <div className={`submit-status ${submitStatus.type}`}>
              {submitStatus.message}
            </div>
          )}
        </form>
      </header>
    </div>
  );
}

export default App;